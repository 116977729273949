import React from "react";
import "tachyons/css/tachyons.min.css";
import "../scss/style.scss";
import Head from "next/head";

const DocBlocApp = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <title>Docbloc</title>
        <link rel="icon" href="/icon.png" />
      </Head>
      <Component {...pageProps} />
      <div id="toast-message"></div>
    </>
  );
};

export default DocBlocApp;
